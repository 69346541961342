.section-trainer{
    padding: rem(80px) 0 rem(108px) 0;
    background: #F8F8F8;
    position: relative;
    overflow: hidden;
    padding-bottom: 2.75rem;

    @include tablets{
        padding: 50px 0 60px 0;
        text-align: center;
    }
}

.section-trainer__bg{
    position: absolute;
    left: rem(-34px);
    top: rem(-55px);
    width: rem(510.41px);
    height: rem(522.16px);
    z-index: 0;
    opacity: 0.05;
}

.section-trainer .h2, .section-calendar .h2, .section-lager .h2{
    font-style: normal;
    font-weight: bold;
    font-size: rem(36px);
    line-height: rem(44px);
    color: #363636;
    margin-bottom: rem(11px);

    @include tablets{
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 31px;
        margin-bottom: 8px;
    }
}

.section-trainer .h3, .section-calendar .h3, .section-lager .h3{
    margin-bottom: rem(24px);

    @include tablets{
        margin-bottom: 20px;
        justify-content: center;
    }
}

.section-trainer__subtitle{
    font-style: normal;
    font-weight: normal;
    font-size: rem(16px);
    line-height: rem(26px);
    color: #363636;
    width: rem(376px);
    max-width: 100%;
    margin: 0;

    @include tablets{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
    }
}

.section-trainer__slider-counter{
    top: 217px;
}

.section-trainer__slider{
    margin: 0 rem(-20px);
    padding-top: rem(58px);
    position: relative;
    min-height: 482.6px;

    @include tablets{
        margin-top: 73px;
        padding-top: 0;
        min-height: 0;
    }
}

.section-trainer__slider-item{
    background: #fff;
    margin: 0 rem(20px);
    text-align: center;
    box-shadow: rem(15px) rem(30px) rem(80px) rgba(107, 121, 164, 0.1);
    border-radius: rem(12px);
    outline: none;
    margin-bottom: rem(20.36px);

    &:hover{
        margin-bottom: 0;
    }

    &:hover .section-trainer__slider-link{
        display: block;

        @include tablets{
            display: none;
        }
    }
}

.section-trainer__slider-item{
    opacity: 1;
}

.section-trainer__slider-item:not(.slick-active){
    opacity: 0;
}

.section-trainer__slider-img{
    height: rem(227px);
    width: 100%;
    border-radius: 50%;
    background-position: 50% 0;
    background-size: 75%;
    background-repeat: no-repeat;
    margin-bottom: rem(32px);
}

.section-trainer__slider .slick-list{
    overflow: visible;
}

.section-trainer__slider-descr{
    padding: 0 rem(30px) rem(30px) rem(30px);
}

.section-trainer__slider-title{
    font-style: normal;
    font-weight: bold;
    font-size: rem(18px);
    line-height: rem(22px);
    text-align: center;
    color: #2B2929;
    margin-bottom: rem(15px);
    min-height: 43.2px;

    @include tablets{
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
    }
}

.section-trainer__slider-text{
    font-style: normal;
    font-weight: 300;
    font-size: rem(16px);
    line-height: rem(22px);
    text-align: center;
    color: #363636;
    margin-bottom: rem(35px);

    @include tablets{
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 0;
    }
}

.section-trainer__slider-link{
    font-weight: 600;
    font-size: rem(18px);
    line-height: rem(21px);
    text-align: center;
    text-decoration-line: underline;
    color: #006633;
    display: none;

    &:hover{
        color: #006633; 
    }
}

.section-trainer__slider .arrow-next, .section-trainer__slider .arrow-prew{
    width: rem(25px);
    position: absolute;

    &:hover{
        cursor: pointer;
    }
}

.section-trainer__slider .arrow-next{
    right: 0;
    top: 0;
    left: auto;
    transform: none;

    @include tablets{
        position: absolute;
        top: -48px!important;
        left: 50%!important;
        transform: translateX(62px-27px)!important;
        z-index: 3;
    }
}

.section-trainer__slider .arrow-prew{
    right: rem(60px);
    top: 0;
    left: auto;
    transform: none;

    @include tablets{
        position: absolute;
        top: -48px!important;
        left: 50%!important;
        transform: translateX(-62px)!important;
        z-index: 3;
    }
}

.section-trainer__slider .slick-dots{
    position: absolute;
    padding: 0;
    right: rem(180px);
    top: 0;
    display: flex;
    list-style: none;
    align-items: center;
}

.section-trainer__slider .slick-dots li{
    margin-right: rem(12px);
    border-radius: 50%;
    overflow: hidden;

    &:last-child{
        margin-right: 0px;
    }
}

.section-trainer__slider .slick-dots li button{
    width: rem(5px);
    height: rem(5px);
    background: #363636;
    opacity: 0.5;
    border: none;
    outline: none;
    font-size: 0px;
    display: block;
    padding: 0;
    margin: 0;
}

.section-trainer__slider .slick-dots .slick-active{
    width: rem(16px);
    height: rem(16px);
    background: #FF2E00;
}

.section-trainer__slider .slick-dots .slick-active button{
    opacity: 1;
    width: rem(16px);
    height: rem(16px);
    background: #FF2E00;
}

.section-trainer .btn-lg{
    display: none;

    @include tablets{
        display: inline-block;
        margin-top: 30px;
        padding: 21px 40px;
    }
}