.main-section{
    padding: rem(19px) 0 0 0;
    position: relative;

    &:before{
        content: "";
        display: block;
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        width: 41.7%;
        background: #006633;
        z-index: -1;

        @include desktop{
            width: 36%;
        }

        @include tablets{
            display: none;
        }
    }
}

.main-section .header .container{
    @include tablets{
        justify-content: center;
    }
}

.main-section-content{
    margin-top: rem(50px);

    @include tablets{
        margin-top: 15px;
    }
}

.main-section-content .container{
    padding-bottom: rem(160px);
    display: flex;
    flex-direction: row;
    position: relative;

    @include tablets{
        padding-bottom: 50px;
    }
}

.main-section-content__left {
    flex: 45%;
    
    @include tablets{
        text-align: center;
        flex: auto;
        width: 100%;
    }
}

.main-section-content__left p{
    width: rem(327px);

    @include tablets{
        width: 100%;
        font-size: 14px;
        line-height: 22px;
    }
}

.main-section-content__left .btn-danger{
    width: rem(210px);
}

.main-section-content__left .btn-lg{
    width: rem(210px);

    @include tablets{
        width: 175px;
        padding: 22px 10px;
        margin: 10px 0 0 0!important;
    }
}

.main-section-content__left .h3{
    margin-bottom: rem(30px);
    
    @include tablets{
        justify-content: center;
    }
}

.main-section-content__left .h1{
    line-height: rem(48px);

    @include tablets{
        font-size: 23px;
        line-height: 32px;
    }
}

.main-section-content__slider{
    flex: 50%;
    max-width: 50%;

    @include tablets{
        display: none;
    }
}

.main-section-content__slider .slick-list{
    border-radius: rem(35px);
    background: #fff;
    box-shadow: 0px rem(4px) rem(35px) rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.main-section-content__slider-item {
    outline: none;
}

.main-section-content__slider-img{
    width: 100%;
    height: rem(277px);
    background-position: center;
    background-size: cover;
    position: relative;
}

.main-section-content__slider-date{
    position: absolute;
    top: rem(40px);
    left: rem(40px);
    background: #FFFFFF;
    border-radius: rem(47px);
    padding: rem(6px) rem(15px);
    font-size: rem(14px);
    line-height: rem(25px);
    color: #363636;
}

.main-section-content__slider-description{
    padding: rem(35px) rem(40px);
    font-style: normal;
    font-weight: 500;
    font-size: rem(18px);
    line-height: rem(26px);
    color: #363636;
}

.main-section-content__slider-description a{
    color: #FF2E00;
    text-decoration: underline;
}

.main-section-content__slider .slick-dots{
    position: absolute;
    bottom: rem(-96px);
    left: 4%;
    transform: translateX(-50%);
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    list-style: none;
    align-items: center;
}

.main-section-content__slider .slick-dots li{
    width: rem(5px);
    height: rem(5px);
    background: #2B2929;
    opacity: 0.5;
    margin-left: rem(10px);
    border-radius: 50%;

    &:first-child{
        margin-left: 0;
    }
}

.main-section-content__slider .slick-dots .slick-active{
    width: rem(16px);
    height: rem(16px);
    background: #FF2E00;
    opacity: 1;
}

.main-section-content__slider .slick-dots li button{
    opacity: 0;
    width: 100%;
    height: 100%;
}

.main-section-content__icons{
    position: absolute;
    bottom: rem(60px);
    left: 0;

    @include tablets{
        display: none;
    }
}

.main-section-content__icons a{
    transition: 0.5s;
    margin-left: rem(23px);

    &:first-child{
        margin-left: 0;
    }

    &:hover{
        text-decoration: none;
    }

    &:hover g{
        opacity: 1;
    }

    &:hover path{
        opacity: 1;
    }
}

.main-section-content__slider-nums{
    position: absolute;
    bottom: rem(60px);
    right: rem(15px);
    font-style: normal;
    font-weight: bold;
    font-size: rem(16px);
    line-height: rem(28px);
    color: #FFFFFF;    

    @include tablets{
        display: none;
    }
}

.main-section-content__slider-nums .prev{
    margin-right: rem(15px);

    &:hover{
        text-decoration: none;
    }
}

.main-section-content__slider-nums .next{
    margin-left: rem(15px);

    &:hover{
        text-decoration: none;
    }
}

.main-section__bg1, .main-section__bg2{
    display: none;
    z-index: -1;

    & svg{
        width: 100%;
        height: 100%;
    }

    @include tablets{
        position: absolute;
        display: block;
    }
}

.main-section__bg1{
    
    @include tablets{
        top: 104px;
        right: -49px;
        width: 235.23px;
        height: 240.65px;
    }
}

.main-section__bg2{
    
    @include tablets{
        bottom: -100px;
        right: 65px;
        width: 349.23px;
        height: 357.27px;
    }
}

.gamer_num{
    margin-right: 10px;
    min-width: 14px;
    display: inline-block;
}