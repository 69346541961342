.main-content{
    padding-bottom: rem(40px);
    margin-top: rem(30px);
}

.main-content h1{
    font-style: normal;
    font-weight: bold;
    font-size: rem(45px);
    line-height: rem(58px);
    color: #363636;
    margin-top: rem(19px);
    margin-bottom: rem(46px);
    max-width: 766px;
    font-size: 0;
    margin-bottom: 0;
    height: 0;

    @include tablets{
        margin: 15px 0 35px 0;
        // font-size: 26px;
        // line-height: 32px;
        margin-bottom: 0;
    }
}

.main-content .normal{
    font-size: rem(45px)!important;
    margin-bottom: rem(46px)!important;
    margin-bottom: rem(46px)!important;
    height: auto!important;

    @include tablets{
        height: auto!important;
        margin: 15px 0 35px 0;
        font-size: 26px!important;
        line-height: 32px!important;
        margin-bottom: 0;
    }
}

.main-content p{
    max-width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;

    & b{
        font-weight: 600;
        margin-bottom: 15px;
    }
}

.main-content a:not([class]){
    color: #063;

    &:hover{
        color: #063;
    }
}

.go-back{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #363636;
    // margin-top: 30px;
    display: inline-block;
    position: relative;
    padding-left: 22px;

    &:hover{
        color: #363636;
        text-decoration: none;
    }

    &::before{
        content: "";
        display: block;
        background: url("../assets/images/arrowTitle.svg");
        background-size: cover;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        width: 7px;
        height: 14px;
    }
}

.category-row{
    margin-bottom: rem(30px);
    margin-right: 60px;

    @include tablets{
        margin-bottom: 47px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        margin-right: 40px;
    }
}

.category-row__dropdown{
    margin-bottom: 47px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    bottom: -1px;
    position: relative;
}

.category-row__item{
    font-style: normal;
    font-weight: 500;
    font-size: rem(16px);
    line-height: rem(26px);
    color: #2B2929!important;
    border-bottom: rem(1.5px) dashed #363636;
    margin-right: rem(30px);
    white-space: nowrap;
    line-height: 2.4rem;

    &:hover{
        color: inherit;
        text-decoration: none;
    }

    @include tablets{
        font-size: 16px;
        line-height: 26px;
        display: none;
        border-bottom: none;
        margin-right: 0;
    }
}

.category-row__dropdown .category-row__item{
    font-size: 16px;
    line-height: 26px;
    display: none;
    border-bottom: none;
    margin-right: 0;
    background-color: #fff;
    border-left: 1px #333 solid;
    border-right: 1px #333 solid;
    padding: 0 5px;


    &:last-child{
        border-bottom: 1px #333 solid;
    }

    &:hover{
        background: #1e90ff;
        color: #fff!important;
    }
}

.category-row__dropdown .active{
    border: none;
    background: none;

    &:hover{
        background: none;
        color: inherit!important;
    }
}

.category-row .active{
    font-style: normal;
    font-weight: bold;
    font-size: rem(16px);
    line-height: rem(26px);
    color: #2B2929;
    border-bottom: none;
    pointer-events: none;
    
    &:after{

        @include tablets{
            content: '';
            display: block;
            background-image: url('../assets/images/dec.svg');
            background-size: cover;
            width: 8px;
            height: 6px;
            position: absolute;
            right: -16px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    @include tablets{
        position: relative;
        display: flex;
        pointer-events: inherit;
        width: max-content;
    }
}

.category-row__dropdown .active{
    position: relative;
    display: flex;
    pointer-events: inherit;
    width: max-content;
    
    &:after{
        content: '';
        display: block;
        background-image: url('../assets/images/dec.svg');
        background-size: cover;
        width: 8px;
        height: 6px;
        position: absolute;
        right: -16px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.category-row_open{
    height: 25px;
    overflow-y: visible;
    flex-wrap: nowrap;
    border-bottom: 1px #333 solid;
}

.category-row_open .category-row__item{
    display: flex;
    order: 2;
    margin-right: 0;
    z-index: 9;
}

.category-row_open .active{
    
    &:after{
        content: '';
        transform: translateY(-50%) rotate(180deg);
    }

    order: 1;
}

.news-list{
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-20px);
    align-items: stretch;
}

.news-item{
    flex: 30%;
    max-width: 30%;
    margin: 0 rem(20px);
    background: #F9F9F9;
    box-shadow: rem(15px) rem(30px) rem(80px) rgba(107, 121, 164, 0.1);
    border-radius: rem(12px);
    overflow: hidden;
    margin-bottom: rem(37px);
    
    &:hover{   
        text-decoration: none;
        color: inherit;
    }

    @include tablets{
        flex: 100%;
        max-width: 100%;
        margin-bottom: rem(17px);
    }
}

.news-list_services .news-item{
    padding-bottom: 1rem;
}

.news-list_services .news-item__img{
    height: 20.0625rem;

    @include tablets{
        height: 290px;
    }
}

.news-item__bottom{
    padding: rem(20px) rem(30px);

    @include tablets{
        padding: 15px 25px 25px 25px;
    }
}

.news-item__img{
    height: rem(225px);
    background-position: center;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    background-color: #fff;

    @include tablets{
        height: 190px;
    }
}

.news-item__img_no-photo{
    background-size: 40%;
}

.news-item__date{
    position: absolute;
    left: rem(30px);
    top: rem(30px);
    background: #FFFFFF;
    border-radius: rem(47px);
    font-style: normal;
    font-weight: 500;
    font-size: rem(14px);
    line-height: rem(25px);
    color: rgba(#363636, .6);
    padding: rem(3px) rem(15px);

    @include tablets{
        display: none;
    }
}

.news-item__title{
    font-style: normal;
    font-weight: bold;
    font-size: rem(16px);
    line-height: rem(26px);
    color: #363636;
    margin-bottom: rem(10px);

    @include tablets{
        margin-bottom: rem(10px);
        font-size: 14px;
        line-height: 22px;
    }
}

.news-item__title__lg{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin: 11px 0;
    position: relative;
    width: min-content;
    white-space: nowrap;
    
    &:after{
        content: "";
        display: block;
        width: rem(8px);
        height: rem(16px);    
        position: absolute;
        right: rem(-18px);
        top: 50%;
        transform: translateY(-50%);
        background-image: url('../assets/images/arrowTitle.svg');
        background-position: center;
        background-size: cover;
    }

    @include tablets{
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 0;
    }
}

.news-item__text{
    font-style: normal;
    font-weight: normal;
    font-size: rem(16px);
    line-height: rem(26px);
    color: #363636;
    margin: 0;

    @include tablets{
        font-size: 14px;
        line-height: 22px;
    }
}

.otdel{
    display: flex;
}

.otdel__map{
    width: rem(400px);
    height: auto;
    margin-right: rem(60px);
    position: relative;

    & #map{
        width: rem(400px);
        height: rem(400px);
        max-width: 100%;
        position: absolute;
        top: 0;
        bottom: auto;
    }

    @include tablets{
        display: none;
    }
}

.otdel__map-slick{
    
    & #map{
        position: fixed;
        top: rem(150px);
    }
}

.otdel__map-slick_btm{
    position: relative;

    & #map{
        position: absolute;
        top: auto;
        bottom: 0;
    }
}

.otdel__adress-item{
    background: #F9F9F9;
    box-shadow: rem(15px) rem(30px) rem(80px) rgba(107, 121, 164, 0.1);
    border-radius: rem(12px);
    color: #363636;
    padding: rem(35px) rem(90px) rem(45px) rem(40px);
    display: block;
    margin-bottom: rem(20px);
    max-width: 640px;

    &:hover{
        text-decoration: none;
        color: #363636;
    }

    @include tablets{
        padding: 25px 25px 35px 25px;
    }
}

.otdel__adress-item h3{
    font-style: normal;
    font-weight: bold;
    font-size: rem(20px);
    line-height: rem(26px);
    position: relative;
    width: min-content;
    margin-bottom: rem(25px);
    white-space: nowrap;

    &:after{
        content: "";
        display: block;
        width: rem(8px);
        height: rem(16px);    
        position: absolute;
        right: rem(-18px);
        top: 50%;
        transform: translateY(-50%);
        background-image: url('../assets/images/arrowTitle.svg');
        background-position: center;
        background-size: cover;
    }

    @include tablets{
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 20px;
    }
}

.otdel__adress-item p, .adress-text{
    font-style: normal;
    font-weight: normal;
    font-size: rem(16px);
    line-height: rem(26px);
    margin: 0;
    position: relative;
    padding-left: 23px;

    &:before{
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 4px;
        width: 9.76px;
        height: 12.77px;
        background: url("../assets/images/point.svg");
        background-size: cover;
    }

    @include tablets{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 26px;
        padding-left: 20px;
    }
}

#map{
    width: rem(400px);
    height: rem(400px);
    max-width: 100%;

    @include tablets{
        height: 200px;
    }
}

.otdel-adr{
    display: flex;

    @include tablets{
        display: block;
    }
}

.otdel-adr__map{
    margin-right: rem(60px);

    @include tablets{
        margin-right: 0;
        width: 100%;
        margin-bottom: 40px;
    }
}

.otdel-adr__descr{
    max-width: rem(496px);
}

.otdel-adr__descr h2{
    font-style: normal;
    font-weight: bold;
    font-size: rem(20px);
    line-height: rem(32px);
    margin-bottom: rem(20px);
    text-transform: uppercase;

    @include tablets{
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 32px;
    }
}

.otdel-adr__descr .adress-text{
    margin-bottom: rem(50px);
    max-width: rem(400px);

    @include tablets{
        margin-bottom: 40px;
    }
}

.otdel-adr__descr .btn-danger{
    padding: rem(22px) rem(56px);
    margin-bottom: rem(50px);

    @include tablets{
        padding: 20px 49px;
        margin-bottom: 60px;
    }
}

.otdel-adr__descr h3{
    font-weight: 600;
    font-size: rem(16px);
    line-height: rem(26px);

    @include tablets{
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 15px;
    }
}

.otdel-adr__descr p{
    font-style: normal;
    font-weight: normal;
    font-size: rem(16px);
    line-height: rem(26px);
    margin-bottom: rem(15px);

    @include tablets{
        font-size: 14px;
        line-height: 26px;
    }
}

.grp-list{
    display: flex;
    margin: 0 rem(-20px);
    flex-wrap: wrap;
    
    @include tablets{
        display: block;
        margin: 0;
    }
}

.grp-item{
    background: #FAFAFA;
    box-shadow: rem(15px) rem(30px) rem(80px) rgba(107, 121, 164, 0.1);
    border-radius: rem(12px);
    margin: 0 rem(20px);
    padding: rem(30px) rem(30px) rem(40px) rem(30px);
    color: #2B2929;
    display: block;
    flex: 20% 1;
    margin-bottom: rem(20px);
    max-width: 21%;

    &:hover{
        text-decoration: none;
        color: #2B2929;
    }
    
    @include tablets{
        margin: 0;
        margin-bottom: 20px;
        padding: 30px 30px 40px 30px;
        flex: 100% 1;
        max-width: 100%;
    }
}

.grp-item h3{
    font-weight: bold;
    font-size: 1.3rem;
    line-height: rem(28px);
    position: relative;
    text-transform: uppercase;
    color: #2B2929;
    max-width: 100%;
    // white-space: nowrap;
    margin-bottom: rem(25px);

    &:after{
        content: "";
        display: block;
        width: rem(8px);
        height: rem(16px);    
        position: absolute;
        right: rem(-18px);
        top: 50%;
        transform: translateY(-50%);
        background-image: url('../assets/images/arrowTitle.svg');
        background-position: center;
        background-size: cover;
    }
}

.grp-item p{
    font-style: normal;
    font-weight: normal;
    font-size: rem(14px);
    line-height: rem(20px);
    margin-bottom: rem(15px);

    &:last-child{
        margin-bottom: 0;
    }
}

.otdel-zps{
    display: flex;
    align-items: flex-start;

    @include tablets{
        flex-direction: column-reverse;
    }
}

.otdel-zps__map{
    margin-right: rem(100px);

    @include tablets{
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        margin-top: 70px;
        margin-right: 0;
    }
}

.otdel-zps__map #map{
    margin-bottom: rem(60px);
}

.otdel-zps__map__lg #map{
    width: 500px;
    height: 500px;
}

.otdel-zps__map h2{
    font-style: normal;
    font-weight: bold;
    font-size: rem(28px);
    line-height: 121%;
    margin-bottom: rem(45px);

    @include tablets{
        order: 1;
        margin-bottom: 30px;
    }
}

.contacts-list{
    list-style: none;
    padding: 0;

    @include tablets{
        margin-bottom: 35px;
    }
}

.contact-item{
    display: flex;
    margin-bottom: rem(30px);
    font-style: normal;
    font-weight: normal;
    font-size: rem(16px);
    line-height: rem(22px);
    max-width: rem(270px);

    @include tablets{
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 16px;
    }
}

.contact-ico{
    margin-right: rem(23px);
    width: rem(23px);

    & svg{
        width: 100%;
    }

    @include tablets{
        width: 17px;
    }
}

.contact-text{
    color: inherit;
    &:hover{
        color: inherit;
        text-decoration: none;
    }
}

.otdel-zps__form{
    background: #F9F9F9;
    box-shadow: rem(15px) rem(30px) rem(80px) rgba(107, 121, 164, 0.1);
    border-radius: rem(12px);
    max-width: rem(500px);
    padding: rem(45px) rem(100px) rem(70px) rem(60px);

    @include tablets{
        background: none;
        box-shadow: none;
        padding: 0;
    }
}

.otdel-zps__form h2{
    font-style: normal;
    font-weight: bold;
    font-size: rem(28px);
    line-height: 121%;
    margin-bottom: rem(20px);

    @include tablets{
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 25px;
    }
}

.otdel-zps__form p{
    font-style: normal;
    font-weight: 300;
    font-size: rem(16px);
    line-height: rem(25px);
    margin-bottom: rem(40px);

    @include tablets{
        font-weight: 300;
        font-size: 14px;
        line-height: 26px;
        max-width: 218px;
        margin-bottom: 0;
    }
}
.otdel-zps__form h3{
    font-style: normal;
    font-weight: 600;
    font-size: rem(18px);
    line-height: rem(25px);
    margin-bottom: rem(20px);
    margin-top: rem(15px);

    @include tablets{
        margin-top: 35px;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 5px;
    }
}

.otdel-zps__form input, .otdel-zps__form .select, .otdel-zps__form textarea{
    width: 100%;
    margin-bottom: rem(20px);

    @include tablets{
        margin-top: 15px;
        margin-bottom: 0;
    }
}

.otdel-zps__form textarea{
    height: 122px;
}

.otdel-zps__form .btn-danger{
    padding: rem(18px) rem(39px);
    margin-top: rem(15px);

    @include tablets{
        margin-top: 30px;
        padding: 19px 52px;
    }
}

.event{
    background: #F9F9F9;
    box-shadow: rem(15px) rem(30px) rem(80px) rgba(107, 121, 164, 0.1);
    border-radius: rem(12px);
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;
    color: #363636;
    margin-bottom: rem(25px);
    min-height: 235px;

    &:hover{
        color: #363636;
        text-decoration: none;
    }

    @include tablets{
        flex-direction: column;
        margin-bottom: 17px;
        min-height: 1px;
    }
}

.event__img{
    flex: 8;
    height: auto;
    background-position: center;
    background-size: cover;

    @include tablets{
        height: 190px;
        flex: auto;
    }
}

.event__desc{
    flex: 24;
    padding: rem(30px) rem(57px) rem(35px) rem(57px);

    @include tablets{
        padding: 16px 25px 25px 25px;
        flex: auto;
    }
}

.event__desc h2{
    font-style: normal;
    font-weight: bold;
    font-size: rem(20px);
    line-height: rem(28px);
    margin-bottom: rem(15px);

    @include tablets{
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
    }
}

.event__desc p{
    font-style: normal;
    font-weight: normal;
    font-size: rem(16px);
    line-height: rem(26px);
    margin-bottom: rem(51px);
    max-width: rem(422px);

    @include tablets{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 0;
    }
}

.event__more{
    font-style: normal;
    font-weight: 500;
    font-size: rem(16px);
    line-height: rem(19px);
    color: #12B45C;
    position: relative;
    width: min-content;
    white-space: nowrap;

    &:after{
        content: "";
        display: block;
        width: rem(7px);
        height: rem(14px);   
        position: absolute;
        right: rem(-17px);
        top: 50%;
        transform: translateY(-50%);
        background-image: url('../assets/images/arrowTitleGreen.svg');
        background-position: center;
        background-size: cover;
    }

    @include tablets{
        display: none;
    }
}

.game{
    padding: rem(30px) rem(60px);
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    color: #363636;
    background: #F9F9F9;
    box-shadow: rem(15px) rem(30px) rem(80px) rgba(107, 121, 164, 0.1);
    border-radius: rem(12px);
    margin-bottom: rem(25px);
    position: relative;

    &:hover{
        color: #363636;
        text-decoration: none;
    }

    @include tablets{
        padding: 25px 15px;
    }
}

.game__team-logo{
    // height: rem(123px);
    width: rem(111px);

    @include tablets{
        position: absolute;
        top: 25px;
        // height: 55px;
        width: 50px;
    }

    &:first-child{
        @include tablets{
            left: 30px;
        }
    }

    &:last-child{
        @include tablets{
            right: 30px;
        }
    }
}

.game__team-logo img{
    width: 100%;
}

.game__desc{
    text-align: center;

    @include tablets{
        display: flex;
        flex-direction: column;
    }
}

.game__desc h2{
    font-style: normal;
    font-weight: bold;
    font-size: rem(18px);
    line-height: rem(26px);
    text-transform: uppercase;
    margin-bottom: rem(10px);

    @include tablets{
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 26px;
    }
}

.game__desc p{
    font-style: normal;
    font-weight: normal;
    font-size: rem(16px);
    line-height: rem(26px);
    margin-bottom: rem(30px);

    @include tablets{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 15px;
    }
}

.game__score{
    font-style: normal;
    font-weight: bold;
    font-size: rem(42px);
    line-height: rem(22px);
    margin-bottom: rem(30px);
    display: block;

    @include tablets{
        order: -1;
        margin-top: 28px;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 22px;
        margin-bottom: 37px;
    }
}

.game__date{
    font-style: normal;
    font-weight: normal;
    font-size: rem(14px);
    line-height: rem(26px);
    color: #8D8D8D;
    display: block;

    @include tablets{
        font-size: 14px;
        line-height: 26px;
    }
}

.gallery{
    display: flex;
    flex-wrap: wrap;
    margin: rem(-20px);

    @include tablets{
        margin: -7.5px;
    }
}

.gallery__item{
    flex: 1 0 21%;
    height: rem(255px);
    margin: rem(20px);
    background-size: cover;
    background-position: center;
    display: block;
    position: relative;
    overflow: hidden;

    @include tablets{
        flex: 1 0 35%;
        margin: 7.5px;
        height: 130px;
    }
}

.gallery__item_video{
    &::after{
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: rem(42px);
        height: rem(42px);
        background: url("../assets/images/playBtn.svg");
        background-position: center;
        background-size: cover;
    }

    &::before{
        content: "Смотреть видео";
        font-style: normal;
        font-weight: bold;
        font-size: rem(14px);
        line-height: rem(26px);
        text-align: center;
        letter-spacing: 0.02em;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        color: #fff;
        background: rgba(#000, 0.4);
        padding-top: rem(150px);
    }

    @include tablets{
        &::before{
            content: "";
            padding: 0;
        }
    }
}

.team h2{
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 26px;
    margin-bottom: 50px;
}

.team__item{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 80px;
    justify-content: center;
}

.team__avatar{
    background-color: #fff;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 378px;
    width: 260px;
    margin-right: 100px;
}

.team__avatar_no-photo{
    background-size: 60%
}

.team__desc{
    max-width: 637px;
}

.team__desc h3{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 15px;
}

.team__desc p{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 25px;
}

.team__desc h4{
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 5px;
}

.team__desc ul{
    padding-left: 15px;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 25px;
}

.players__list{
    margin-top: -25px;

    @include tablets{
        margin-top: -10px;
    }
}

.players{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    margin-top: 85px;

    @include tablets{
        margin-top: 60px;
        margin: 0;
    }
}

.players h2{
    font-weight: 600;
    font-size: 36px;
    line-height: 26px;
    margin: 0 20px;
    margin-bottom: 50px;
    flex: 100%;

    @include tablets{
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 26px;
        margin-left: 0;
        margin-right: 0;
    }
}

.player{
    flex: 21.6% 0;
    margin: 20px;
    background-color: #fff;
    box-shadow: 15px 30px 80px rgba(107, 121, 164, 0.1);
    border-radius: 12px;
    overflow: hidden;

    @include tablets{
        flex: 100%;
        margin: 10px 0;
    }
}

.player__avatar{
    height: 345px;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: #fff;

    @include tablets{
        height: 460px;
    }
}

.player__avatar_no-photo{
    background-position: center;
    background-size: 70%;
}

.player__desc{
    padding: 15px 42px 30px 42px;
    text-align: center;
    min-height: 152px;

    @include tablets{
        padding: 18px 50px 25px;
        min-height: 147px;
    }
}

.player__desc .player__name{
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;

    @include tablets{
        margin-bottom: 10px;
    }
}

.player__desc p{
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    color: #363636;
    margin: 0;
}