.section-contact{
    height: rem(625px);
    background: url('../assets/images/contactBG.svg');
    background-position: center;
    background-size: cover;

    @include tablets{
        height: auto;
        padding-bottom: 60px;
        background-size: auto 1058px;
        background-position-x: -605px;
        background-position-y: 0;
    }
}

.section-contact .container{
    position: relative;
    height: 100%;
}

.section-contact__first{
    position: absolute;
    left: 0;
    bottom: rem(67px);
    padding: rem(45px) rem(52px) rem(37px) rem(52px);
    background: #FFFFFF;
    border-radius: rem(12px);
    width: rem(500px);

    @include tablets{
        bottom: auto;
        left: auto;
        position: relative;
        width: 100%;
        margin-top: -94px;
        padding: 35px 20px;
    }
}

.section-contact__first .h2{
    margin-bottom: rem(25px);

    @include tablets{
        font-style: normal;
        font-weight: bold;
        font-size: 26px!important;
        line-height: 31px!important;
        text-align: center;
        margin-bottom: 20px;
    }
}

.section-contact__first p{
    font-style: normal;
    font-weight: 300;
    font-size: rem(18px);
    line-height: rem(25px);
    color: #363636;
    width: rem(273px);
    max-width: 100%;
    margin-bottom: rem(30px);

    @include tablets{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        max-width: 215px;
        margin: auto;
        margin-bottom: 20px;
    }
}

.section-contact .h2{
    font-style: normal;
    font-weight: bold;
    font-size: rem(36px);
    line-height: 121%;
    color: #2B2929;
}

.section-contact input{
    width: 100%;
    margin-bottom: rem(15px);
}

.section-contact .btn-lg{
    @include tablets{
        padding: 22px 40px;
    }
}

.section-contact textarea{
    width: 100%;
    min-height: rem(135px);
    max-height: rem(235px);
    margin-bottom: rem(30px);

    @include tablets{
        margin-bottom: 25px;
    }
}

.section-contact__second{
    position: absolute;
    width: rem(530px);
    left: rem(560px);
    bottom: rem(67px);
    padding: rem(45px) rem(70px) rem(62px) rem(70px);
    background: #FFFFFF;
    border-radius: rem(12px);

    @include tablets{
        bottom: auto;
        left: auto;
        position: relative;
        width: 100%;
        margin-top: 25px;
        padding: 35px 20px;
    }
}

.section-contact__second h2{
    margin-bottom: rem(45px);

    @include tablets{
        font-style: normal;
        font-weight: bold;
        font-size: 26px!important;
        line-height: 31px!important;
        text-align: center;
        margin-bottom: 35px;
    }
}

.section-contact__list{
    list-style: none;
    padding: 0;
    margin: 0;
}

.section-contact__item{
    display: flex;
    margin-bottom: rem(37px);
    vertical-align: center;

    &:last-child{
        @include tablets{
            margin-bottom: 0;
        }
    }

    @include tablets{
        margin-bottom: 22px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
    }
}

.section-contact__item-ico{
    margin-right: rem(23px);
    width: rem(23px);
    display: flex;

    & svg{
        width: 100%;
    }

    @include tablets{
        width: 18px;
        margin-right: 15px;
        margin-left: 12px;
    }
}

.section-contact__item-text{
    font-style: normal;
    font-weight: normal;
    font-size: rem(16px);
    line-height: rem(22px);
    color: #363636;
    display: flex;
    width: rem(215px);
    max-width: 100%;

    &:hover{
        text-decoration: none;
        color: inherit;
    }
}