// adjustment variables
$body-font: 'Gilroy', sans-serif;
$body-font-size: 16px;

// vars


// layout
@import "_misc/fonts.scss";
@import "_misc/mixins.scss";
@import "_misc/adjustment.scss";

// styles
@import "sections/header.scss";
@import "sections/mainsection.scss";
@import "sections/mobile-news-section.scss";
@import "sections/expirience.scss";
@import "sections/section-program.scss";
@import "sections/section-trainer.scss";
@import "sections/section-calendar.scss";
@import "sections/section-lager.scss";
@import "sections/section-achievements.scss";
@import "sections/section-contact.scss";
@import "sections/footer.scss";
@import "sections/breadcrumbs.scss";
@import "sections/main-content.scss";

//media
@import "adaptive/media.scss";
