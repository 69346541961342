.section-achievements{
    padding: rem(68px) 0 rem(222px) 0;
    position: relative;
    background: #F8F8F8;
    overflow: hidden;

    @include tablets{
        padding: 50px 0 174px 0;
        text-align: center;
    }
}

.section-achievements__bg1{
    position: absolute;
    left: rem(522px);
    top: rem(154px);
    width: rem(210.06px);
    height: rem(215.04px);

    & svg{
        width: 100%;
        height: 100%;
    }

    @include tablets{
        width: 293px;
        height: 300px;
        left: -40px;
        top: -40px;
    }
}

.section-achievements__bg2{
    position: absolute;
    right: rem(-13px);
    bottom: rem(93px);
    width: rem(323px);
    height: rem(253px);

    & svg{
        width: 100%;
        height: 100%;
    }

    @include tablets{
        width: 176px;
        height: 138.02px;
        bottom: 25px;
        right: -8px;
    }
}

.section-achievements__bg3{
    position: absolute;
    left: rem(-103px);
    bottom: rem(-238px);
    width: rem(526.07px);
    height: rem(538.55px);

    & svg{
        width: 100%;
        height: 100%;
    }

    @include tablets{
        display: none;
    }
}

.section-achievements .h2{
    font-style: normal;
    font-weight: bold;
    font-size: rem(36px);
    line-height: rem(44px);
    color: #363636;
    width: rem(335px);
    max-width: 100%;

    @include tablets{
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 31px;
        text-align: center;
        max-width: 238px;
        margin: auto;
    }
}

.section-achievements .card-crcl-list{
    margin-top: rem(50px);
    margin-right: rem(-70px);

    @include tablets{
        margin-top: 40px;
        margin-right: 0;
    }
}

.section-achievements .card-crcl{
    padding: rem(60px) rem(60px) rem(66px) rem(130px);
    height: auto;
    margin-right: rem(70px);

    @include tablets{
        padding: 20px 20px 30px 20px;
        margin-right: 0;
        margin-bottom: 15px;
        display: block;
    }
}

.section-achievements .card-crcl__img{
    top: rem(40px);
    transform: translate(-50% 0);

    @include tablets{
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        width: auto;
        height: auto;
        margin-bottom: 15px;
    }
}

.card-crcl__text h4{
    font-style: normal;
    font-weight: bold;
    font-size: rem(22px);
    line-height: rem(27px);
    color: #2B2929;
    margin-bottom: rem(22px);

    @include tablets{
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 10px;
    }
}

.card-crcl__text p{
    font-style: normal;
    font-weight: normal;
    font-size: rem(16px);
    line-height: rem(24px);
    color: #2B2929;
    padding: 0;
    margin-bottom: rem(28px);

    @include tablets{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 30px;
    }
}

.card-crcl__text a{
    font-style: normal;
    font-weight: bold;
    font-size: rem(18px);
    line-height: rem(22px);
    text-decoration-line: underline;
    color: #006633;
}

.section-achievements .card-crcl__text a{

    @include tablets{
        border-radius: 40px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
		line-height: 16px;
		padding: 18px 40px;
        display: inline-block;
        outline: none;
        border: none;
        text-align: center;
        border: 1px solid #12b45c;
        border-radius: 40px;
        color: #2B2929;
        text-decoration: none;
        
        &:hover{
            text-decoration: none;
        }
    }
}