.expirience{
    padding: rem(80px) 0 rem(100px) 0;
    background: #F9F9F9;
    position: relative;
    overflow: hidden;

    @include tablets{
        padding: 40px 0 60px 0;
        text-align: center;
    }
}

.expirience .container{
    position: relative;
    z-index: 1;
}

.expirience .h3{
    @include tablets{
        justify-content: center;
    }
}

.card-crcl-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: rem(40px);
    margin-bottom: rem(-40px);

    @include tablets{
        display: block;
        margin-top: 77px;
        margin-bottom: 0;
    }
}

.card-crcl{
    background: #F9F9F9;
    box-shadow: rem(15px) rem(30px) rem(80px) rgba(107, 121, 164, 0.1);
    border-radius: rem(12px);
    flex: 30%;
    height: rem(196px);
    margin-right: rem(55px);
    margin-bottom: rem(40px);
    padding: 0 rem(35px) 0 rem(87px);
    position: relative;
    align-items: center;
    display: flex;

    &:nth-child(3n){
        margin-right: 0;

        @include tablets{
            margin: 0 7.5px;
        }
    }

    @include tablets{
        padding: 20px 40px 30px 40px;
        height: auto;
        margin: 0 7.5px;
    }
}

.card-crcl__img{
    position: absolute;
    top: 50%;
    left: rem(14px);
    transform: translate(-50%, -50%);
    width: rem(112px);
    height: rem(112px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FAFAFA;
    border-radius: 50%;

    @include tablets{
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        margin: auto;
    }
}

.card-crcl__img img{
    height: rem(91px);
}

.card-crcl__text{
    font-style: normal;
    font-weight: normal;
    font-size: rem(16px);
    line-height: rem(26px);
    color: #2B2929;

    @include tablets{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
    }
}

.card-crcl__text a{
    font-style: normal;
    font-weight: normal;
    font-size: rem(18px);
    line-height: rem(22px);
    color: #2B2929;
    text-decoration: underline;

    @include tablets{
        font-style: normal;
        font-weight: normal;
        font-size: 14px!important;
        line-height: 22px!important;
    }
}

.expirience__bg1{
    position: absolute;
    z-index: 0;
    left: rem(-15px);
    top: rem(-12px);

    @include tablets{
        left: -86px;
        top: -43px;
    }
}

.expirience__bg1 svg{
    width: rem(408.75px);
    height: rem(418.45px);

    @include tablets{
        width: 286px;
        height: 292px;
    }
}

.expirience__bg2{
    position: absolute;
    z-index: 0;
    right: rem(-15px);
    bottom: rem(-276px);

    @include tablets{
        display: none;
    }
}

.expirience__bg2 svg{
    width: rem(530.75px);
}

.expirience__bg3{
    position: absolute;
    z-index: 0;
    right: rem(102px);
    top: rem(112px);

    @include tablets{
        display: none;
    }
}

.expirience__bg3 svg{
    width: rem(186.94px);
    height: rem(146.56px);
}

.expirience .slick-track{
    display: flex;
}