#breadcrumbs{
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: rem(8px);
    display: flex;
    flex-wrap: wrap;

    @include tablets{
        margin-top: 20px;
        display: inline-block;
    }
}

#breadcrumbs li{
    font-style: normal;
    font-weight: 500;
    font-size: rem(16px);
    line-height: rem(26px);

    & a{
        color: #9B9B9B;
    }

    &:last-child a{
        color: #363636;
        pointer-events: none;
    }

    &:after{
        content: "/";
        margin-right: rem(3px);
    }

    &:last-child:after{
        content: "";
    }

    @include tablets{
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        display: inline;
    }
}