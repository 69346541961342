.footer{
    padding: rem(29px) 0 rem(45px) 0;
    background: #fff;
    z-index: 2;
    position: relative;

    @include tablets{
        padding: 30px 0 83px 0;
    }
}

.footer .container{
    position: relative;
}

.footer .header{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include tablets{
        display: block;
    }
}

.footer .logo{
    height: rem(117px);

    @include tablets{
        height: 75px;
        justify-content: center;
    }
}

.footer .logo__left{
    margin-right: rem(51px);

    @include tablets{
        margin-right: 20px;
    }
}

.footer .logo__right{
    filter: none;
}

.footer .header__contacts{
    align-items: center;
}

.footer .header__contacts-item{
    margin-left: rem(50px);

    &:last-child{
        @include tablets{
            display: none;
        }
    }

    @include tablets{
        position: absolute;
        top: 226px;
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
        display: flex;
        align-items: center;
    }
}

.footer .header__contacts-item .header__contacts-text{

    @include tablets{
        margin-left: 17px;
    }
}

.footer .header__contacts-item .header__contacts-text a:first-child{
    opacity: 1;
    text-decoration: none;
    border: none;

    @include tablets{
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        display: block!important;
    }
}

.footer .header__contacts-item .header__contacts-text a:last-child{
    @include tablets{
        display: none;
    }
}

.footer .header__right{
    @include tablets{
        height: 0;
    }
}

.footer .header__contacts-text, .footer .header__contacts-text a{
    color: #2B2929;
}

.footer .header__contacts-ico svg{
    @include tablets{
        width: 14px;
    }
}

.footer .header__contacts-ico:before{
    background:#363636;
    opacity: 0.1;

    @include tablets{
        content: "";
        display: block;
        width: 29px;
        height: 29px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.footer__menu{
    margin-top: rem(60px);
    display: flex;
    justify-content: space-between;

    @include tablets{
        margin-top: 35px;
        display: block;
        text-align: center;
    }
}

.footer__menu-title{
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: rem(16px);
    line-height: rem(19px);
    color: #363636;
    text-transform: uppercase;
    margin-bottom: rem(20px);

    @include tablets{
        margin-bottom: 0;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
    }
}

.footer__menu-clm{
    @include tablets{
        display: inline-block;
        margin: 10px;
    }
}

.footer__menu-clm a:not(.footer__menu-title){
    font-style: normal;
    font-weight: normal;
    font-size: rem(14px);
    line-height: rem(16px);
    color: #363636;
    display: block;
    margin-bottom: rem(15px);
    max-width: rem(224px);

    @include tablets{
        display: none;
    }
}

.footer__menu-clm:last-child{
    text-align: right;
}

.footer__icons{
    position: absolute;
    bottom: rem(109px);
    right: 0;

    @include tablets{
        position: relative;
        bottom: auto;
        right: auto;
        margin-top: 85px;
    }
}

.footer__social-icons{
    display: flex;
    justify-content: flex-end;
    margin-bottom: rem(30px);

    @include tablets{
        justify-content: center;
    }
}

.footer__social-icons a{
    width: rem(45px);
    margin-left: rem(30px);

    & svg{
        width: 100%;
    }
    
    @include tablets{
        width: 35px;
        margin-left: 18px;
    }
}

.footer__pay-icons{
    text-align: right;

    @include tablets{
        text-align: center;
        position: absolute;
        left: 0;
        top: 200px;
        right: 0;
    }
}

.footer__pay-icons img{
    margin-left: rem(30px);
    height: rem(35px);

    @include tablets{
        height: 19px;
        margin-left: 19px;
    }
}

.footer__description{
    margin-top: rem(82px);
    display: flex;
    justify-content: space-between;

    @include tablets{
        margin-top: 50px;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.footer__dev span{
    font-style: normal;
    font-weight: normal;
    font-size: rem(14px);
    line-height: rem(16px);
    color: #363636;
}

.footer__dev a{
    font-style: normal;
    font-weight: bold;
    font-size: rem(14px);
    line-height: rem(16px);
    color: #363636;
}

.footer__copy{
    font-style: normal;
    font-weight: normal;
    font-size: rem(14px);
    line-height: rem(16px);
    color: #999999;

    @include tablets{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        max-width: 212px;
        margin-top: 15px;
    }
}