.section-program{
    padding: rem(55px) 0 rem(100px) 0;
    background: #006633;
    position: relative;
    overflow: hidden;

    @include tablets{
        text-align: center;
        padding: 50px 0 60px 0;
    }
}

.section-program__bg{
    position: absolute;
    top: rem(-126px);
    left: rem(-7px);

    @include tablets{
        top: 145px;
        left: auto;
        right: -6px;
    }
}

.section-program__bg svg{
    width: rem(322px);
    height: rem(252px);

    @include tablets{
        width: 186.94px;
        height: 146.56px;
    }
}

.section-program .h2{
    font-style: normal;
    font-weight: bold;
    font-size: rem(36px);
    line-height: 121%;
    color: #FFFFFF;
    margin-bottom: rem(25px);

    @include tablets{
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 121%;
        margin-bottom: 20px;
    }
}

.section-program__description{
    font-style: normal;
    font-weight: normal;
    font-size: rem(16px);
    line-height: rem(26px);
    color: #FFFFFF;
    width: rem(560px);
    max-width: 100%;

    @include tablets{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
    }
}

.section-program__slider-counter{
    @include tablets{
        top: 204px;
    }
}

.programs{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: rem(39px);

    @include tablets{
        display: block;
        margin-top: 70px;

        & .program__description{
            opacity: 1;
            animation: cardswipe2 0.8s forwards;
        }
    
        & .program__main-photo{
            opacity: 1;
            animation: cardswipe 0.4s forwards;
        }
    }
}

.program_open{

    & .program__description{
        opacity: 1;
        animation: cardswipe2 0.8s forwards;
    }

    & .program__main-photo{
        opacity: 1;
        animation: cardswipe 0.4s forwards;
    }
}

.program{
    height: rem(388px);
    flex: 20%;
    margin-right: rem(40px);
    border-radius: rem(12px);
    overflow: hidden;
    position: relative;
    box-shadow: rem(15px) rem(30px) rem(80px) rgba(107, 121, 164, 0.1)!important;
    background: none!important;

    &:nth-child(4n){
        margin-right: 0;

        @include tablets{
            margin: 0 7.5px;
        }
    }

    &:hover .program__description{
        opacity: 1;
        // animation: cardswipe2 0.8s forwards;
    }

    &:hover .program__main-photo{
        opacity: 1;
        // animation: cardswipe 0.4s forwards;
    }
}

@keyframes cardswipe{
    0%{
        
    }

    100%{
        transform: rotate3d(0, 1, 0, 90deg);
    }
}

@keyframes cardswipe2{
    0%{
        opacity: 0;
    }

    49%{
        opacity: 0;
    }

    50%{
        opacity: 1;
        transform: rotate3d(0, 1, 0, 90deg);
    }

    100%{
        transform: rotate3d(0, 1, 0, 0deg);
    }
}

@keyframes cardswipe3{
    0%{
        opacity: 1;
        transform: rotate3d(0, 1, 0, 0deg);
    }

    99%{
        opacity: 1;
    }

    100%{
        opacity: 0;
        transform: rotate3d(0, 1, 0, 90deg);
    }
}

@keyframes cardswipe4{
    0%{
        opacity: 1;
    }

    50%{
        transform: rotate3d(0, 1, 0, 90deg);
        opacity: 1;
    }

    100%{
        opacity: 1;
        transform: rotate3d(0, 1, 0, 0deg);
    }
}

.program__main-photo{
    height: 100%;
    background-position: center;
    background-size: cover;
    position: relative;
    filter: grayscale(100%); 
    // animation: cardswipe4 0.8s;
    border-radius: rem(12px);
    overflow: hidden;

    &:before{
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: #636363;
        opacity: 0.5;
    }
}

.program__main-title{
    position: absolute;
    bottom: rem(30px);
    left: rem(30px);
    font-style: normal;
    font-weight: bold;
    font-size: rem(22px);
    line-height: rem(30px);
    text-transform: uppercase;
    color: #FFFFFF;
}

.program__description{
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #FAFAFA;
    // animation: cardswipe3 0.4s forwards;
    padding: rem(30px);
    border-radius: rem(12px);
    overflow: hidden;
    transition: 0.2s opacity;

    @include tablets{
        padding: 30px 20px 16px 20px;
    }
}

.program__description-title{
    font-style: normal;
    font-weight: bold;
    font-size: rem(22px);
    line-height: rem(28px);
    text-transform: uppercase;
    color: #2B2929;
    margin-bottom: rem(25px);

    @include tablets{
        font-weight: 800;
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 25px;
    }
}

.program__description p{
    font-style: normal;
    font-weight: 300;
    font-size: rem(14px);
    line-height: rem(18px);
    color: #2B2929;
    margin-bottom: rem(20px);

    @include tablets{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 20px;
    }
}

.program__description .btn{
    position: absolute;
    bottom: rem(30px);
    left: rem(30px);
    right: rem(30px);

    @include tablets{
        font-size: 14px;
        padding: 20px 40px;
    }
}