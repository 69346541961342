.mobile-news-section{
    position: relative;
    padding: 40px 0 60px 0;
    display: none;

    &:before{
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 331px;
        background: #363636;
        z-index: -1;
    }

    @include tablets{
        display: block;
    }
}

.mobile-news-section__bg{
    position: absolute;
    top: 33px;
    right: -24px;
    width: 186px;
    height: 146px;

    & svg{
        width: 100%;
        height: 100%;
    }
}

.mobile-news-section .h2{
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 70px;
}

.mobile-news-section__slider-counter, .expirience-section__slider-counter, .section-program__slider-counter, .section-trainer__slider-counter{
    display: none;
    position: absolute;
    top: 86px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    color: #fff;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;

    @include tablets{
        display: block;
    }
}

.expirience-section__slider-counter, .section-trainer__slider-counter{
    color: #363636;
}

.mobile-news-section__slider .arrow-prew, .card-crcl-list .arrow-prew, .programs .arrow-prew{
    position: absolute;
    top: -48px!important;
    left: 50%!important;
    transform: translateX(-62px)!important;
    z-index: 3;
}

.mobile-news-section__slider .arrow-next, .card-crcl-list .arrow-next, .programs .arrow-next{
    position: absolute;
    top: -48px!important;
    left: 50%!important;
    transform: translateX(62px-27px)!important;
    z-index: 3;
}

.mobile-news-section__slider .slick-list, .card-crcl-list .slick-list, .programs .slick-list, .section-trainer .slick-list{
    overflow: visible;
}

.mobile-news-section__slider .main-section-content__slider-item, .programs .program, .section-trainer__slider-item{
    background: #FFFFFF;
    box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    width: 240px;
    margin: 0 7.5px!important;
}

.mobile-news-section__slider .main-section-content__slider-img{
    height: 191px;
}

.mobile-news-section__slider .main-section-content__slider-description{
    position: relative;
    padding: 15px 20px 57px 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #363636;
}

.mobile-news-section__slider .main-section-content__slider-date{
    left: 20px;
    bottom: 15px;
    top: auto;
    padding: 0;
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 25px;
    color: #363636;
    opacity: 0.6;
    background: none;
}