.section-calendar{
    padding: rem(65px) 0 rem(90px) 0;
    background: #F8F8F8;
    position: relative;
    overflow: hidden;

    @include tablets{
        padding: 50px 0 60px 0;
        text-align: center;
    }
}

.section-calendar__bg1{
    position: absolute;
    left: rem(15px);
    top: 0;
    width: rem(167.8px);
    height: rem(131.54px);
    
    & svg{
        width: 100%;
        height: 100%;
    }

    @include tablets{
        width: 176px;
        height: 138.02px;
        top: 15px;
        left: -36px;
    }
}

.section-calendar__bg2{
    position: absolute;
    right: rem(-22px);
    top: rem(180px);
    width: rem(262.2px);
    height: rem(205.56px);
    
    & svg{
        width: 100%;
        height: 100%;
    }

    @include tablets{
        width: 176px;
        height: 138.02px;
        bottom: 226px;
        top: auto;
        right: -50px;
    }
}

.section-calendar .h2{

    @include tablets{
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 31px;
        margin-bottom: 8px;
    }
}

.section-calendar .section-trainer__subtitle{
    width: rem(445px);

    @include tablets{
        max-width: 258px;
        margin: auto;
    }
}

.section-calendar__list{
    margin-top: rem(70px);
    display: flex;
    flex-wrap: wrap;
    margin-right: rem(-40px);
    margin-bottom: rem(-40px);

    @include tablets{
        margin-top: 40px;
    }
}

.section-calendar__item{
    background: #F9F9F9;
    border-radius: rem(12px);
    position: relative;
    overflow: hidden;
    display: flex;
    flex: 45%;
    box-shadow: rem(15px) rem(30px) rem(80px) rgba(107, 121, 164, 0.1);
    margin-right: rem(40px);
    margin-bottom: rem(40px);
    padding: rem(40px) rem(100px) rem(40px) rem(45px);
    align-items: center;
    pointer-events: none;

    &:hover{
        text-decoration: none;
        color: inherit;
    }

    @include tablets{
        max-width: 100%;
        margin-bottom: 15px;
        display: block;
        padding: 25px;
        text-align: center;
        min-height: 158.6px;
    }
}

.section-calendar__date-year{
    position: absolute;
    font-style: normal;
    font-weight: 800;
    font-size: rem(110px);
    line-height: rem(22px);
    color: #EDEEF1;
    right: rem(-25px);
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;

    @include tablets{
        font-weight: 800;
        font-size: 110px;
        line-height: 22px;
        top: 50px;
        right: -14px;
    }
}

.section-calendar__date{
    position: relative;
    z-index: 1;
    text-align: center;
    width: rem(86px);
    margin-right: rem(55px);

    @include tablets{
        width: 100%;
        text-align: left;
        display: flex;
        align-items: flex-end;
    }
}

.section-calendar__date-num{
    font-weight: bold;
    font-size: rem(32px);
    line-height: rem(22px);
    color: #363636;

    @include tablets{
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 22px;
    }
}

.section-calendar__date-month{
    font-weight: bold;
    font-size: rem(18px);
    line-height: rem(22px);
    letter-spacing: 0.055em;
    color: #363636;

    @include tablets{
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.055em;
        margin-left: 10px;
    }
}

.section-calendar__text{
    position: relative;
    z-index: 1;
    flex: 67%;
    font-style: normal;
    font-weight: 600;
    font-size: rem(18px);
    line-height: rem(26px);
    color: #363636;

    @include tablets{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
        margin-top: 15px;
        max-width: 100%;
    }
}

.section-calendar .btn-lg{
    margin-top: rem(45px);

    @include tablets{
        margin-top: 66px;
        padding: 21px 40px;
    }
}