header{
    padding: 19px 0;
    top: -100px;
}

.main-section .header{
    padding: 0;
}

.header_fixed{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    background: #fff;
    padding: rem(19px) 0!important;
    box-shadow: 0px rem(5px) rem(15px) rgba(54, 54, 54, 0.05);
    transition: top 1s;
}

header .container{
    display: flex;
    justify-content: space-between;

    @include tablets{
        justify-content: center;
    }
}

.header__profile{
    display: none;
    position: absolute;
    left: rem(20px);
    top: rem(20px);

    @include tablets{
        display: block;
    }
}

.logo{
    height: rem(97px);
    display: flex;

    @include tablets{
        height: 94px;
    }
}

.logo__left{
    margin-right: rem(25px);
}

header .logo__left{
    @include tablets{
        margin-right: 0;
    }
}

.logo__right{
    filter: grayscale(100%); 
    transition: 0.5s;
    
    &:hover{
        filter: grayscale(0); 
    }
}

header .logo__right{


    @include tablets{
        display: none;
    }
}

.header__right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

header .header__right{
    @include tablets{
        display: flex;
        width: 73%;
        position: fixed;
        top: 0;
        left: -73%;
        bottom: 0;
        background: #363636;
        z-index: 9999;
        padding: rem(30px) rem(20px);
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: flex-start;
        transition: 0.2s;
    }

    &:after{
        @include tablets{
            content: "";
            display: none;
            background: #363636;
            opacity: 0;
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
        }
    }
}

header .header__right_open{
    left: 0;

    & ~ .header__menu-btn svg:last-child{
        display: flex;
    }
    & ~ .header__menu-btn svg:first-child{
        display: none;
    }

    @include tablets{
        display: flex;
    }

    &:after{
        @include tablets{
            display: block;
            opacity: 0.45;
        }
    }
}

.header__contacts{
    display: flex;

    @include tablets{
        flex-direction: column;
        margin-top: rem(50px);
    }
}

.header__contacts-item{
    display: flex;
    margin-left: rem(30px);

    &:first-child{
        margin-left: 0;

        @include tablets{
            margin-top: 0;
        }
    }

    &:last-child{
        @include tablets{
            display: none;
        }
    }

    @include tablets{
        margin: rem(30px) 0 0 0; 
    }
}

.header__contacts-ico{
    width: rem(29px);
    height: rem(29px);
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;

    &:before{
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(#363636, 0.1);
        opacity: 1;
        border-radius: 50%;

        @include tablets{
            display: none;
        }
    }

    & path{
        // fill: #fff;

        @include tablets{
            fill: #363636;
        }
    }

    @include tablets{
        height: 14px;
        width: 14px;
    }
}

header .header__contacts-ico path{
    fill: #363636;

    @include tablets{
        fill: #fff;
    }
}

.main-section .header__contacts-ico{
    

    &:before{
        background: #FFFFFF;
        opacity: 0.3;

        @include tablets{
            display: none;
        }
    }

    & path{
        fill: #fff;
    }
}

.header_fixed .header__contacts-ico{

    &:before{
        background: rgba(#363636, 0.1);
        opacity: 1;
    }

    & path{
        fill: #363636;
    }
}

.header__contacts-ico img{
    display: flex;
}

.header__contacts-text{
    margin-left: rem(12px);
    color: #363636;

    @include tablets{
        margin-left: 6px;
    }
}

.main-section .header__contacts-text{
    color: #fff;
}

.header__contacts-text a:first-child, .header__contacts-text div{
    display: block;
    font-weight: bold;
    font-size: rem(18px);
    line-height: rem(22px);
    margin: 0;
    padding: 0;
    border-bottom: none;
    text-decoration: none;
    opacity: 1;

    @include tablets{
        font-size: 16px;
        line-height: 19px;
    }
}

.header__contacts-text a{
    font-weight: 500;
    font-size: rem(12px);
    line-height: rem(14px);
    opacity: 0.8;
    text-decoration: none;
    transition: 0.5s;
    color: #fff;
    margin: 0;
    padding: 0;
    
    &:hover{
        opacity: 1;
    }

    @include tablets{
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
        opacity: 0.8;
        border-bottom: 1px dashed #fff;
        margin-left: -20px;
    }
}

header .header__contacts-text div, header .header__contacts-text a{
    color: #363636;

    @include tablets{
        color: #fff;
    }
}

.main-section .header__contacts-text div, .main-section .header__contacts-text a{
    color: #fff;
}

.header_fixed .header__contacts-text div, .header_fixed .header__contacts-text a{
    color: #363636;
}

.header__menu{
    display: flex;
    margin-top: rem(23px);

    @include tablets{
        flex-direction: column;
        margin: 0;
    }
}

.header__menu-item{
    display: flex;
    margin-left: rem(60px);
    font-weight: bold;
    font-size: rem(13px);
    line-height: rem(16px);
    color: #363636;
    text-transform: uppercase;

    &:hover{
        color: inherit;
    }

    &:last-child{
        & svg{

            @include tablets{
                margin-left: 8px;
            }
        }

        @include tablets{
            margin: 0;
        }
    }

    @include tablets{
        color: #fff;
        margin: 0 0 22px 0;
        font-size: 13px;
        line-height: 16px;
    }
}

.main-section .header__menu-item{
    &:first-child{
        margin-left: 0px;
    }

    &:nth-last-child(-n+5){
        color: #fff;
    }

    &:nth-last-child(-n+5):hover{
        color: #fff;
    }
}

.header_fixed .header__menu-item{
    color: #363636!important;
}

.header__right .main-section-content__icons{
    display: none;
    position: relative;
    left: auto;
    bottom: auto;
    margin-top: rem(34px);

    @include tablets{
        display: flex;
    }
}

.header__menu-btn{
    display: none;
    cursor: pointer;
    
    @include tablets{
        display: flex;
        position: fixed;
        right: 20px;
        top: 20px;
        z-index: 99999;
    }
}

.header__menu-btn svg:last-child{
    display: none;
}