%clearfix{
	&:after, &:before {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}
}

%vertical-align{
	&:before {
		content: "";
		display: inline-block;
		width: 0;
		height: 100%;
		vertical-align: middle;
	}
}

//responsive
@function rem($px) {
	@return $px / $body-font-size + 0rem;
}


@mixin desktop {
	@media screen and (max-width: 1400px) {
		@content;
	}
}

@mixin laptop {
	@media screen and (max-width: 992px) {
		@content;
	}
}


@mixin tablets {
	@media screen and (max-width: 980px) {
		@content;
	}
}


@mixin phones {
	@media screen and (max-width: 576px) {
		@content;
	}
}
