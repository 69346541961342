// adjustment styles
html {
	font-size: $body-font-size;
	line-height: 24px;
	color: #363636;
}

body {
	font-family: $body-font;
	-webkit-font-smoothing: antialiased;
}

html, body {
	width: 100%;
	height: 100%;
}

.wrapper {
	min-height: 100%;
	overflow: hidden;
	position: relative;
}

.bg-1{
	background: #F9F9F9;
}

.bg-1 section{
	z-index: 2;
	position: relative;
	padding-bottom: 80px;

	@include tablets{
		padding-bottom: 50px;
	}
}

.wrapper-bg-1{
	position: absolute;
	right: 0;
	top: 211px;

	& svg{
		@include tablets{
			width: 100%;
			height: 100%;
		}
	}

	@include tablets{
		width: 235.23px;
		height: 240.65px;
		top: 112px;
		right: -54px;
	}
}

.wrapper-bg-2{
	position: absolute;
	left: 0;
    bottom: 720px;

	& svg{
		@include tablets{
			width: 100%;
			height: 100%;
		}
	}

	@include tablets{
		width: 278px;
		height: 284px;
		left: -59px;
		bottom: 735px;
	}
}

.wrapper-bg-3{
	display: none;

	@include tablets{
		display: block;
		position: absolute;
		bottom: 584px;
		right: -23px;
	}
}

@media (min-width: 992px){
	.container, .container-sm, .container-md, .container-lg{
		max-width: 1160px;
		padding: 0;
	}
}

h3, .h3{
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	color: #363636;
	display: flex;
	flex-direction: row;
}

.h3_red{
	color: #FF2E00!important;
}

h3 span, .h3 span{
	display: flex;
	align-self: center;
}

.title-ico{
	margin-right: 8px;
}

h1, .h1{
	font-style: normal;
	font-weight: 800;
	font-size: 40px;
	line-height: 46px;
	color: #2B2929;

	@include tablets{
		font-size: 23px;
        line-height: 32px;
	}
}

h2, .h2{
	font-style: normal;
	font-weight: bold!important;
	font-size: 45px;
	line-height: 54px;
	color: #363636;
	margin-bottom: 10px;

	@include tablets{
		font-style: normal;
		font-weight: bold;
		font-size: 26px;
		line-height: 31px;
		margin-bottom: 8px;
	}
}

h4 .h4{
	font-style: normal;
	font-weight: bold!important;
	font-size: 26px;
	line-height: 35px;
}

h5, .h5{
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	text-transform: uppercase;
}

.mb-4{
	margin-bottom: 25px!important;

	@include tablets{
		margin-bottom: 14px!important;
	}
}

.mb-5{
	margin-bottom: 60px!important;

	@include tablets{
		margin-bottom: 36px!important;
	}
}

.mr-3{
	margin-right: 20px!important;
}

.btn{
	height: 53px;
	background: #FF2E00;
	border-radius: 28.5px;
	display: inline-block;
	padding: 14px 40px;
	outline: none;
	border: none;

	@include tablets{
		height: auto;
		padding: 18px 40px;
	}
}

.btn-lg{
	border-radius: 40px;
	padding: 20px 40px;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	display: inline-block;
	outline: none;
	border: none;
	text-align: center;
	
	&:hover{
		text-decoration: none;
	}

	@include tablets{
		font-size: 14px;
		line-height: 16px;
		padding: 18px 40px;
	}
}

input, textarea, select{
	border: 1px solid #DDDDDD;
	border-radius: 11px;
	background: #fff;
	padding: 17px 20px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	outline: none;
	box-shadow: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	@include tablets{
		font-size: 14px;
		line-height: 16px;
	}
}	

input[type="date"]{
	padding: 16.2px 20px;
}

select{
	width: 100%;
}

.select{
	position: relative;
	pointer-events: none;

	& select{
		pointer-events: all;
	}

	&::after{
		content: "";
		display: block;
		position: absolute;
		width: 11px;
		height: 8px;
		background: url('../assets/images/select-ico.svg');
		background-size: cover;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.btn-danger{
	background: #FF2E00;
	color: #FFF;

	&:hover{
		background: #DC2902;
	}
}

.btn-outline-success{
	border: 1px solid #12B45C;
	border-radius: 40px;
	color: #2B2929;
}