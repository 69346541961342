.section-lager{
    padding: rem(71px) 0 rem(62px) 0;
    position: relative;
    background: #363636;

    &:before{
        @include tablets{
            content: "";
            display: block;
            position: absolute;
            left: 15px;
            top: 15px;
            width: 56px;
            height: 54px;
            background-image: url('../assets/images/88e466b6031b57a15bd6550462be8a18.png');
            background-size: cover;
            background-position: center;
            opacity: 0.6;
        }
    }

    @include tablets{
        padding: 50px 0 60px 0;
        text-align: center;
    }
}

.section-lager__bg{
    position: absolute;
    left: rem(11px);
    top: rem(18px);
    width: rem(859px);
    height: rem(212.27px);   

    & svg{
        width: 100%;
        height: 100%;
    }

    @include tablets{
        width: 882.19px;
        height: 218px;
        left: -281px;
        top: 24px
    }
}

.section-lager__content{
    width: 57%;

    @include tablets{
        width: 100%;
    }
}

.section-lager .h2, .section-lager .h3, .section-lager .h4, .section-lager .h5, .section-lager .btn-lg{
    color: #fff;
}

.section-lager .h2{

    @include tablets{
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 31px;
        text-align: center;
        max-width: 280px;
        margin: auto;
        margin-bottom: 8px;
    }
}

.section-lager .h3{
    margin-bottom: rem(51px);

    @include tablets{
        max-width: 213px;
        margin: auto;
        margin-bottom: 30px;
    }
}

.section-lager .h3 .title-ico{

    @include tablets{
        align-self: flex-start;
    }
}

.section-lager .h3_red{
    margin-bottom: rem(13px);
}

.section-lager .h4{
    margin-bottom: rem(40px);
    width: rem(290px);

    @include tablets{
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        margin: auto;
        margin-bottom: 30px;
    }
}

.section-lager .h5{
    margin-bottom: rem(20px);

    @include tablets{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 15px;
    }
}

.section-lager__list{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rem(50px);

    @include tablets{
        justify-content: center;
        margin-bottom: 30px;
    }
}

.section-lager__list-item{
    display: flex;
    padding: rem(7px) rem(28px);
    margin-right: rem(15px);
    margin-bottom: rem(15px);
    font-style: normal;
    font-weight: bold;
    font-size: rem(18px);
    line-height: rem(31px);
    color: #FFFFFF;
    background: rgba(#FFFFFF, 0.05);
    box-shadow: rem(8px) rem(16px) rem(80px) rgba(107, 121, 164, 0.08);
    border-radius: rem(12px);
    pointer-events: none;

    &:hover{
        cursor: pointer;
        text-decoration: none;
        color: #fff;
    }

    @include tablets{
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 31px;
        padding: 1px 13px;
        margin: 5px;
    }
}

.section-lager__footer{
    margin-top: rem(63px);
    display: flex;
    align-items: center;

    @include tablets{
        margin: auto;
        margin-top: 30px;
        position: relative;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        max-width: 255px;
    }
}

.section-lager__footer-ico{
    display: flex;
    height: rem(20px);
    margin-right: rem(15px);

    & svg{
        @include tablets{
            height: 100%;
        }
    }
    
    @include tablets{
        position: absolute;
        top: 5px;
        left: 10px;
        height: 15px;
    }
}

.section-lager__footer-text{
    font-style: normal;
    font-weight: normal;
    font-size: rem(16px);
    line-height: rem(25px);
    color: #FFFFFF;
}

.section-lager__slider{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 44.5%;

    &:before{
        content: "";
        display: block;
        background: url('../assets/images/88e466b6031b57a15bd6550462be8a18.png');
        background-position: center;
        background-size: cover;
        width: rem(109px);
        height: rem(105px);
        position: absolute;
        top: rem(85px);
        right: rem(140px);
        z-index: 3;
    }

    @include tablets{
        display: none;
    }
}

.section-lager__slider-item, .section-lager__slider .slick-list, .section-lager__slider .slick-track{
    height: 100%;
}

.section-lager__slider-item{
    position: relative;
    background-size: cover;
    background-position: center;

    &:before{
        content: "";
        background: rgba(#363636,0.23);
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
}

.section-lager__slider .arrow-next{
    position: absolute;
    right: rem(140px);
    bottom: rem(72px);
    z-index: 3;
    cursor: pointer;
}

.section-lager__slider .arrow-prew{
    position: absolute;
    right: rem(244px);
    bottom: rem(72px);
    z-index: 3;
    cursor: pointer;
}

.section-lager__slider-info{
    position: absolute;
    right: rem(183px);
    bottom: rem(65px);
    color: #fff;
    font-style: normal;
    font-weight: bold;
    font-size: rem(16px);
    line-height: rem(28px);

    @include tablets{
        display: none;
    }
}