@media screen and (max-width: 1400px) { //hd
	html {
		font-size: $body-font-size - 2px;
	}
}

@media screen and (max-width: 992px) { //laptop
	html {
		font-size: $body-font-size - 4px;
	}
}

@media screen and (max-width: 768px) { //tablets
	html {
		font-size: $body-font-size;
	}
}

@media screen and (max-width: 480px) { //phones
	html {
		font-size: $body-font-size;
	}
}

//@media screen and (min-width: 10px) { //phones
//	body {
//		background: orange;
//	}
//}
//
//@media screen and (min-width: 480px) { //phones
//	body {
//		background: yellow;
//	}
//}
//
//@media screen and (min-width: 768px) { //tablets
//	body {
//		background: green;
//	}
//}
//
//@media screen and (min-width: 992px) { //laptop
//	body {
//		background: red;
//	}
//}
//
//@media screen and (min-width: 1200px) { //hd
//	body {
//		background: black;
//	}
// }